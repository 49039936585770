import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Canon from "../components/vandegraaf/canon.module.scss";
import Collection from "../components/vandegraaf/collection";
import Metatags from "../components/metatags";
import Spread from "../components/vandegraaf/spread";
import PageNumber from "../components/vandegraaf/pagenumber";
import Footer from "../components/footer";
import Img from "../images/canon/hero.jpg";
import Examples from "../images/canon/constructions.png";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Metatags title="Van de Graaf | May 5 | CSS Grid a day" description="An example of using CSS grid every day, from May 1–31st, 2019." thumbnail="https://cssgrid31.brett.cool/opengraph/may-5.png" url="https://cssgrid31.brett.cool" pathname="/may-5" mdxType="Metatags" />
    <Collection mdxType="Collection">
  <Spread mdxType="Spread">
    <div className={`${Canon.t0} ${Canon.te4h} ${Canon.ln1} ${Canon.re0}`}>
      <img className={Canon.cover} src={Img} alt="Hero Image" />
    </div>
    <h1 className={`${Canon.t5} ${Canon.l1} ${Canon.le7} ${Canon.m0} ${Canon.ae}`}>
      Canons of page construction
    </h1>
    <h2 className={`${Canon.t6} ${Canon.l1} ${Canon.le7} ${Canon.ae} ${Canon.m0}`}>
      A CSS Grid example
    </h2>
    <div className={`${Canon.t4} ${Canon.ae} ${Canon.r1} ${Canon.re7}`}>
      <h2 className={Canon.mt0}>The Van de Graaf canon</h2>
      <p>The creation of simplistic page layouts is deceptively complex.</p>
      <p>Though the grids and margins used to lay out print publications are invisible, you’d immediately notice if they were absent.</p>
      <p className={Canon.mb0}>Using the Van de Graaf canon, you always end up with a 9×9 grid. Use this to help proportion your inner and outer margins:</p>
    </div>
    <div className={`${Canon.t5} ${Canon.r1} ${Canon.ren1}`}>
      <img src={Examples} alt="Canon constructions" />
      <p><em>See—“<a href="http://retinart.net/graphic-design/secret-law-of-page-harmony/">The Secret Law of Page Harmony</a>”</em>.</p>
    </div>
    <PageNumber side="left" mdxType="PageNumber">1</PageNumber>
    <PageNumber side="right" mdxType="PageNumber">2</PageNumber>
  </Spread>  
    </Collection>
    <Footer date={5} prev={true} next={true} mdxType="Footer">
      <p>{`I remember coming across the Van de Graaf Canon some years ago, from an article entitled `}<a parentName="p" {...{
          "href": "http://retinart.net/graphic-design/secret-law-of-page-harmony/",
          "title": "The Secret Law of Page Harmony"
        }}>{`“The Secret Law of Page Harmony”`}</a>{` on Retinart.`}</p>
      <p>{`I’m fascinated by the prospect of rich layouts using CSS grids that we more commonly associate with print design.`}</p>
      <p>{`Granted, using ratios and proportions for physical printed pages doesn’t make sense given the different context, but I wanted to see if it would be possible to use CSS Grid to visualise page construction canons.`}</p>
      <p>{`Turns out it’s quite possible`}</p>
      <p><em parentName="p"><a parentName="em" {...{
            "href": "https://unsplash.com/photos/eY7ETwocMyU",
            "title": "Photo by Banter Snaps on Unsplash"
          }}>{`Photo by Banter Snaps on Unsplash`}</a></em></p>
    </Footer>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      